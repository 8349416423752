import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState, Status } from './store';
import axiosInstance from '../axiosConfig';
import { Character } from './charactersSlice';
import { Environment } from './environmentsSlice';
import { DateTime } from 'luxon';

const OFFLINE_IDLE_EARNING_IN_HRS = 3;

interface UserState {
  id: number | null;
  apiaryId: number | null;
  token: string | null;
  isLoggedIn: boolean;
  status: Status;
  error: string | null;
  energy: number;
  totalEnergy: number;
  baseEnergy: number;
  lastEnergyUpdate: string | null;
  currentCoins: number;
  totalCoins: number;
  coinsPerTap: number;
  idleCoinsPerHour: number;
  apiaries: any[] | null;
  characterImage: string | null;
  deleteAccountStatus: 'idle' | 'deleted',
  lastActive: string | null;
  lastIdleUpdate: string | null;
}

const initialState: UserState = {
  id: null,
  apiaryId: null,
  token: null,
  isLoggedIn: false,
  status: 'idle',
  error: null,
  energy: 1000,
  totalEnergy: 1000,
  baseEnergy: 1000,
  lastEnergyUpdate: null,
  currentCoins: 0,
  totalCoins: 1000,
  idleCoinsPerHour: 0,
  coinsPerTap: 1,
  apiaries: [],
  characterImage: null,
  deleteAccountStatus: 'idle',
  lastActive: null,
  lastIdleUpdate: null,
};

export const fetchUser = createAsyncThunk('user/fetchUser', async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.get('/users/me', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
});

export const updateUserLastActive = createAsyncThunk('user/updateLastActive', async () => {
  const response = await axiosInstance.patch('/users/sync');
  return response.data;
});

export const deleteUser = createAsyncThunk('user/deleteUser', async () => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.delete('/users/me', {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
});

export const handleTap = createAsyncThunk('user/handleTap', async (taps: number) => {
  const token = localStorage.getItem('token');
  const response = await axiosInstance.post('/users/tap', { taps }, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
});

const calculateEnergy = (energy: number, totalEnergy: number, lastEnergyUpdate: string) => {
  const now = new Date();
  const lastUpdate = new Date(lastEnergyUpdate);
  const secondsElapsed = Math.floor((now.getTime() - lastUpdate.getTime()) / 1000);
  return Math.min(totalEnergy, energy + secondsElapsed);
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authenticateUser(state, action: PayloadAction<{ user: any; token: string }>) {
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.apiaryId = action.payload.user.apiaries[0]?.id || null; // Assuming user has at least one apiary
    },
    logoutUser(state) {
      state.apiaryId = null;
      state.token = null;
      state.isLoggedIn = false;
      state.status = 'idle';
      state.error = null;
    },
    addCoins(state) {
      state.currentCoins += state.coinsPerTap;
      state.totalCoins += state.coinsPerTap;
      state.energy -= state.coinsPerTap;
    },
    deductCurrentCoins(state, action: PayloadAction<{ amount: number }>) {
      state.currentCoins -= action.payload.amount;
    },
    addCoinsFromOrder(state, action: PayloadAction<{ amount: number }>) {
      state.currentCoins += action.payload.amount;
      state.totalCoins += action.payload.amount;
    },
    setTotalEnergy(state, action: PayloadAction<number>) {
      state.baseEnergy = action.payload;
      if (state.energy > state.baseEnergy) {
        state.energy = state.baseEnergy;
      }
    },
    boostTotalEnergy(state) {
      state.totalEnergy += 500;
    },
    decreaseEnergy(state, action: PayloadAction<number>) {
      state.energy -= action.payload;
    },
    regenerateFullEnergy(state) {
      state.energy = state.totalEnergy;
    },
    setCoinsPerTap(state, action: PayloadAction<number>) {
      state.coinsPerTap = action.payload;
    },
    regenerateEnergy(state) {
      if (state.energy < state.totalEnergy) {
        state.energy += 1;
      }
    },
    addIdleCoins(state, action: PayloadAction<{ amount: number }>) {
      state.idleCoinsPerHour += action.payload.amount;
    },
    setCharacterImage(state, action: PayloadAction<string>) {
      state.characterImage = 'tap_' + action.payload.replace('.jpg', '.png')
    },
    setCharacter(state, action: PayloadAction<{ character: Character }>) {
      if (state.apiaries && state.apiaries.length > 0) {
        state.apiaries[0].character = action.payload.character;
      }
    },
    setEnvironment(state, action: PayloadAction<{ environment: Environment }>) {
      if (state.apiaries && state.apiaries.length > 0) {
        state.apiaries[0].environment = action.payload.environment;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUser.fulfilled, (state, action) => {

        const { coinsPerTap, totalCoins, currentCoins, totalEnergy,
          energy, lastEnergyUpdate, apiaries, idleCoinsPerHour,
          id, lastIdleUpdate, lastActive } = action.payload;

        state.id = id;
        state.coinsPerTap = coinsPerTap;
        state.totalCoins = totalCoins;
        state.currentCoins = currentCoins;
        state.totalEnergy = totalEnergy;
        state.energy = calculateEnergy(energy, totalEnergy, lastEnergyUpdate);
        state.lastEnergyUpdate = lastEnergyUpdate;
        state.idleCoinsPerHour = idleCoinsPerHour;
        state.lastIdleUpdate = lastIdleUpdate;
        state.lastActive = lastActive;

        state.status = 'succeeded';
        state.apiaryId = action.payload.apiaries[0]?.id || null; // Assuming user has at least one apiary
        state.apiaries = apiaries;

        if (action.payload.apiaries[0]?.character) {
          state.characterImage = 'tap_' + action.payload.apiaries[0]?.character?.image.replace('.jpg', '.png')
        }
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(handleTap.fulfilled, (state, action) => {
        const { totalCoins } = action.payload;
        state.totalCoins = totalCoins;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.deleteAccountStatus = 'deleted';
      })
      .addCase(updateUserLastActive.fulfilled, (state) => {
        state.lastActive = new Date().toISOString();
      })
  },
});

export const {
  authenticateUser,
  logoutUser,
  addCoins,
  setTotalEnergy,
  decreaseEnergy,
  setCoinsPerTap,
  regenerateEnergy,
  deductCurrentCoins,
  setCharacterImage,
  addCoinsFromOrder,
  boostTotalEnergy,
  regenerateFullEnergy,
  addIdleCoins,
  setCharacter,
  setEnvironment,
} = userSlice.actions;

export default userSlice.reducer;

export const selectUserId = (state: RootState): number | null => state.user.id;
export const selectApiaryId = (state: RootState): number | null => state.user.apiaryId;
export const selectUserStatus = (state: RootState): Status => state.user.status;
export const selectUserError = (state: RootState): string | null => state.user.error;
export const selectCurrentCoins = (state: RootState): number => state.user.currentCoins;
export const selectTotalCoins = (state: RootState): number => state.user.totalCoins;
export const selectEnergy = (state: RootState): number => state.user.energy;
export const selectTotalEnergy = (state: RootState): number => state.user.totalEnergy;
export const selectBaseEnergy = (state: RootState): number => state.user.baseEnergy;
export const selectCoinsPerTap = (state: RootState): number => state.user.coinsPerTap;
export const selectApiaries = (state: RootState) => state.user.apiaries;
export const selectCharacterImage = (state: RootState): string | null => state.user.characterImage;
export const selectDeleteAccountStatus = (state: RootState): string => state.user.deleteAccountStatus;
export const selectIdleCoinsPerHour = (state: RootState): number => state.user.idleCoinsPerHour;