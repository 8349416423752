import { IonContent, IonPage } from '@ionic/react';
import './BuzzTab.css';
import TapComponent from '../components/TapComponent/TapComponent'
import Points from '../components/Coins'
import EnergyAndBoost from '../components/EnergyAndBoost/EnergyAndBoost';
import ProfileHeader from '../components/ProfileHeader/ProfileHeader';
import { useAppDispatch, useAppSelector } from '../hooks';
import { regenerateEnergy } from '../redux/userSlice';
import { useEffect, useRef, useState } from 'react';
import { fetchProducts, getProductsStatus } from '../redux/productSlice';
import TutorialModal from '../components/TutorialModal';
import { TUTORIAL_MODAL_SHOWN_LS_KEY } from '../components/TutorialModal/TutorialModal';
import { CloudStorage, initCloudStorage } from '@telegram-apps/sdk-react';


const BuzzTab: React.FC = () => {

  const dispatch = useAppDispatch();
  const productsStatus = useAppSelector(getProductsStatus);

  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const cloudStorage = useRef<CloudStorage>();

  const closeTutorialModal = () => {
    setShowTutorialModal(true);

    if (cloudStorage.current) {
      cloudStorage.current.set(TUTORIAL_MODAL_SHOWN_LS_KEY, 'true');
    } else {
      localStorage.setItem(TUTORIAL_MODAL_SHOWN_LS_KEY, 'true');
    }
  }

  useEffect(() => {
    setTimeout( async () => {
      let showModal = false
      try {
         const cs = initCloudStorage();
         showModal = await cs.get(TUTORIAL_MODAL_SHOWN_LS_KEY) !== 'true';
         cloudStorage.current = cs;
      } catch {
        showModal = localStorage.getItem(TUTORIAL_MODAL_SHOWN_LS_KEY) !== 'true';
      }

      setShowTutorialModal(showModal);
    }, 1000)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(regenerateEnergy());
    }, 1000);
    return () => clearInterval(interval);
  }, [dispatch]);

  useEffect(() => {
    if (productsStatus === 'idle') {
      dispatch(fetchProducts());
    }
  }, [productsStatus])

  return (
    <IonPage>
      <IonContent>
        <ProfileHeader />
        <div className="hb-padding">
          <Points />
          <TapComponent />
          <EnergyAndBoost />
        </div>

        {showTutorialModal && <TutorialModal isOpen={true} onDidDismiss={() => closeTutorialModal()} />}

      </IonContent>
    </IonPage>
  );
};

export default BuzzTab;
