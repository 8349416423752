import React, { useRef, useState, useEffect } from 'react';
import { IonButton, IonModal, useIonToast, IonIcon, IonLabel } from '@ionic/react';
import { useAppDispatch } from '../../hooks';
import './TaskModal.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { closeOutline } from 'ionicons/icons';
import Coin from '../Coin';
import { DateTime } from 'luxon';
import { formatNumber } from '../../utils';
import { Task, claimTasksReward } from '../../redux/tasksSlice';
import { addCoinsFromOrder } from '../../redux/userSlice';

interface TaskModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  task: Task;
  icon: IconNameEnum;
}

const TaskWrapper: React.FC<{ isOpen: boolean, onDidDismiss: () => void, task: Task | undefined, icon: IconNameEnum }> = ({ isOpen, onDidDismiss, task, icon }) => {

  if (!task) return;

  return <TaskModal isOpen={isOpen} onDidDismiss={onDidDismiss} task={task} icon={icon} />
}

const TaskModal: React.FC<TaskModalProps> = ({ isOpen, onDidDismiss, task, icon }) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const dispatch = useAppDispatch();
  const [present] = useIonToast();

  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [lastRefreshTime, setLastRefreshTime] = useState<DateTime | null>(null);

  const rewardClaimedToast = () => {
    present({
      message: 'Reward successfully claimed!',
      duration: 1000,
      position: 'top',
      color: 'success'
    });
  };

  const taskNotCompletedToast = () => {
    present({
      message: 'Task not completed.',
      duration: 1000,
      position: 'top',
      color: 'danger'
    });
  }

  const handleClaimReward = async (activeTask: Task) => {
    const now = DateTime.now();
    if (lastRefreshTime) {
      const diffInSeconds = now.diff(lastRefreshTime, 'seconds').seconds;
      if (diffInSeconds < 60) {
        return taskNotCompletedToast();
      }
    }

    const res: any = await dispatch(claimTasksReward(activeTask.id))
    if (res.error) {
      taskNotCompletedToast();
    } else {
      dispatch(addCoinsFromOrder({ amount: activeTask.reward }));
      rewardClaimedToast();
      modal.current?.dismiss();
    }
    setLastRefreshTime(now);
  };

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content task-modal'>
        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-8'>
            <HBIcon name={icon} className='icon' size={72} />
          </div>

          <h2 className='text-3xl mb-4 text-center'>{task?.title}</h2>
          <p className='text-center text-sm mb-4'>{task?.description}</p>

          <div className='flex text-2xl font-medium'>
            <Coin size={28} /> +{formatNumber(task?.reward || 0)}
          </div>
        </div>

        {!task.isCompleted &&
          <IonButton color='primary' className='mb-6' expand='block' onClick={() => handleClaimReward(task)}>
            Check
          </IonButton>
        }
      </div>
    </IonModal>
  );
};

export default TaskWrapper;
