import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import characterReducer from './charactersSlice';
import environmentsReducer from './environmentsSlice';
import userReducer from './userSlice';
import apiaryReducer from './apiarySlice';
import productsReducer from './productSlice';
import permanentBoostersReducer from './permanentBoostersSlice';
import dailyBoostersReducer from './dailyBoostersSlice';
import stockReducer from './stockSlice';
import resourcesReducer from './resourcesSlice';
import ordersReducer from './orderSlice';
import dailyTasksReducer from './dailyTasksSlice';
import videoTasksReducer from './videoTasksSlice';
import socialMediaTasksReducer from './socialMediaTasksSlice';
import tasksReducer from './tasksSlice';
import referralsReducer from './referralSlice';

export type Status = 'idle' | 'loading' | 'succeeded' | 'failed';

export const store = configureStore({
  reducer: {
    characters: characterReducer,
    environments: environmentsReducer,
    user: userReducer,
    apiary: apiaryReducer,
    products: productsReducer,
    permanentBoosters: permanentBoostersReducer,
    dailyBoosters: dailyBoostersReducer,
    stock: stockReducer,
    resources: resourcesReducer,
    orders: ordersReducer,
    dailyTasks: dailyTasksReducer,
    videoTasks: videoTasksReducer,
    socialMediaTasks: socialMediaTasksReducer,
    referrals: referralsReducer,
    tasks: tasksReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
