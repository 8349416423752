import React from 'react';
import { IonSpinner } from '@ionic/react';
import './HBSpinner.scss';

const HBSpinner: React.FC<{ className?: string }> = ({ className = '' }) => {

  return <div className={`flex items-center justify-center w-full hb-spinner ${className}`}>
    <IonSpinner className='w-12 h-12' name="crescent" color='primary'></IonSpinner>
  </div>
};

export default HBSpinner;
