import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import { SDKProvider } from '@telegram-apps/sdk-react';
import env from './environments';

import * as Sentry from "@sentry/react";

if (env.name === 'production' || env.name === 'staging') {
  Sentry.init({
    dsn: "https://2b3c39fcf0abd284fce2ce65413d0d8c@o4507826847612928.ingest.de.sentry.io/4507866147651664",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, // Capture 100% of transactions
    tracePropagationTargets: ["localhost", /^https:\/\/(buzzy-bee|buzzy-bee-stg)\.hivebits\.io/],

    // Profiling
    profilesSampleRate: 1.0, // Profile 100% of transactions

    // Session Replay
    replaysSessionSampleRate: 0.1, // 10% of sessions, adjust in production if needed
    replaysOnErrorSampleRate: 1.0,  // Always sample sessions on errors
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <SDKProvider acceptCustomStyles>
    <Provider store={store}>
      <App />
    </Provider>
  </SDKProvider>
  // </React.StrictMode>
);