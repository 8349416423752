// src/pages/ProductsPage.tsx
import React, { useRef } from 'react';
import { IonButton, IonModal, useIonToast, IonIcon, IonAvatar } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import './OrderModal.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { addCoinsFromOrder, deductCurrentCoins, selectCurrentCoins } from '../../redux/userSlice';
import { closeOutline } from 'ionicons/icons';
import { reduceProductStock, selectAllStock, setStatusIdle as setStockStatusIdle } from '../../redux/stockSlice';
import { Order, fulfillOrder } from '../../redux/orderSlice';
import { formatNumber } from '../../utils';

interface OrderModalProps {
  order: Order | null;
  isOpen: boolean;
  onDidDismiss: () => void;
}

const OrderModal: React.FC<OrderModalProps> = ({ order, isOpen, onDidDismiss }) => {
  const stock = useAppSelector(selectAllStock);
  const productStock = stock.find(s => s.productId === order?.product.id);
  const disableButton = productStock && order ? productStock.currentStock < order.quantity : true;


  const modal = useRef<HTMLIonModalElement>(null);

  const dispatch = useAppDispatch();

  const [present] = useIonToast();
  const orderFulfilledToast = () => {
    present({
      message: 'Order successfully fulfilled!',
      duration: 1000,
      position: 'top',
      color: 'success'
    });
  };

  const handleFullfilOrder = async (orderId: number) => {
    if (!order) return;

    try {
      await dispatch(fulfillOrder(orderId));

      dispatch(addCoinsFromOrder({ amount: order ? order.quantity * order.product.valueInCoins : 0 }));
      dispatch(reduceProductStock({ productId: order?.product.id, amount: order.quantity}))

      orderFulfilledToast();
      modal.current?.dismiss();
    } catch(e) {
      console.log(e);
    }
  }


  if (!order) return;


  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content order-modal'>

        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-7'>
            <img className='icon w-36 rounded-full' src={`/assets/${order?.customer?.avatar}`} />
          </div>

          <h2 className='text-2xl mb-4'>{order.customer.name}</h2>

          <p>has order</p>

          <h2 className='text-2xl mb-1'>{order.quantity} {order.product.name}</h2>
          <p className='mb-4'>in stock: {productStock?.currentStock}</p>

          <div className='mb-4'>
            <img className='icon w-20 rounded-md' src={`/assets/${order.product.image}`} />
          </div>

          <HBIcon name={IconNameEnum.Dot} size={42} />

          <div className='flex items-center text-3xl'>
            <HBIcon name={IconNameEnum.Coin} size={36} className='mr-2' /> {formatNumber(order.quantity * order.product.valueInCoins)}
          </div>
        </div>

        <IonButton disabled={disableButton} className='mb-6' expand='block' onClick={(e) => {
          handleFullfilOrder(order.id);
        } }>
          {disableButton ? 'Out of stock' : 'Fullfil'}
        </IonButton>
      </div>
    </IonModal>
  );
};

export default OrderModal;
