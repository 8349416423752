// src/components/TapComponent/TapComponent.tsx
import React, { useRef, useState } from 'react';
import './TapComponent.css';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { addCoins, handleTap, selectCharacterImage, selectCoinsPerTap, selectCurrentCoins, selectEnergy } from '../../redux/userSlice';
import { useAppSelector } from '../../hooks';
import { useAnalytics } from '../../contexts/AnalyticsContext';


const TapComponent: React.FC = () => {
  const hexagonRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const coinsPerTap = useAppSelector(selectCoinsPerTap);
  const characterImage = useAppSelector(selectCharacterImage);
  const userEnergy = useAppSelector(selectEnergy);
  const currentCoins = useAppSelector(selectCurrentCoins);

  const [taps, setTaps] = useState(1);
  const tapIntervalRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { trackEvent } = useAnalytics();


  const handlePointerUp = (event: React.MouseEvent) => {

    trackEvent('button', 'click', 'tap component');

    if (userEnergy < coinsPerTap) return;

    const hexagon = hexagonRef.current;
    if (hexagon) {
      const hexagonRect = hexagon.getBoundingClientRect();
      const hexagonCenterX = hexagonRect.left + hexagonRect.width / 2;
      const hexagonCenterY = hexagonRect.top + hexagonRect.height / 2;
      const offsetX = (event.clientX - hexagonCenterX) / hexagonRect.width;
      const offsetY = (event.clientY - hexagonCenterY) / hexagonRect.height;
      const angleX = -offsetY * 15; // Tilt intensity
      const angleY = offsetX * 15; // Tilt intensity

      hexagon.style.transform = `rotateX(${angleX}deg) rotateY(${angleY}deg)`;
      setTimeout(() => {
        hexagon.style.transform = 'rotateX(0) rotateY(0)';
      }, 200);
    }

    const x = event.clientX;
    const y = event.clientY;
    const newPoint = document.createElement('div');
    newPoint.className = 'point-indicator';
    newPoint.style.left = `${x}px`;
    newPoint.style.top = `${y}px`;
    newPoint.textContent = `+${coinsPerTap}`;
    document.body.appendChild(newPoint);
    setTimeout(() => {
      newPoint.remove();
    }, 500);

    dispatch(addCoins());
    setTaps(taps + 1);

    debouncedHandleTap(taps);
  };

  const debouncedHandleTap = (coins: number) => {
    if (tapIntervalRef.current) {
      clearTimeout(tapIntervalRef.current);
    }

    tapIntervalRef.current = setTimeout(() => {
      dispatch(handleTap(coins));
      setTaps(1);
    }, 1000);
  };

  return (
    <div
      className="bee-container"
      onPointerUp={handlePointerUp}
    >
      <div className="hexagon" ref={hexagonRef}>
        <div className="hexagon-border"></div>
        <img src={`/assets/${characterImage}`} alt="Bee" className="bee" />
      </div>
    </div>
  );
};

export default TapComponent;
