import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../axiosConfig';
import { Status } from './store';

export enum SocialMediaTaskType {
  TELEGRAM = 'TELEGRAM',
  TWITTER = 'TWITTER',
  TELEGRAM_PARTNERSHIP = 'TELEGRAM_PARTNERSHIP',
}

// Define the state interface
export interface SocialMediaTask {
  id: number;
  title: string;
  subtitle?: string;
  description: string;
  url: string;
  isCompleted: boolean;
  reward: number;
  startedAt: string | null; // Allow null for tasks not started yet
  requiredWatchTimeMinutes: number;
  icon: string;
  type: SocialMediaTaskType;
}

interface SocialMediaTaskState {
  socialMediaTasks: SocialMediaTask[];
  status: Status;
  error: string | null;
}

// Initial state
const initialState: SocialMediaTaskState = {
  socialMediaTasks: [],
  status: 'idle',
  error: null,
};

// Create an async thunk to fetch video tasks from the API
export const fetchSocialMediaTasks = createAsyncThunk('socialMediaTasks/fetchSocialMediaTasks', async () => {
  const response = await axiosInstance.get('/tasks/social');
  return response.data;
});

// Create an async thunk to start a video task
export const startSocialMediaTasks = createAsyncThunk('socialMediaTasks/startSocialMediaTasks', async (socialMediaTaskId: number) => {
  const response = await axiosInstance.put(`/tasks/social/${socialMediaTaskId}`);
  return response.data;
});

export const claimSocialMediaTasksReward = createAsyncThunk('socialMediaTasks/claimSocialMediaTasksReward', async (socialMediaTaskId: number) => {
  const response = await axiosInstance.put(`/tasks/social/${socialMediaTaskId}/claim`);
  return response.data;
});

// Create the slice
const socialMediaTaskSlice = createSlice({
  name: 'socialMediaTasks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSocialMediaTasks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSocialMediaTasks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.socialMediaTasks = (action.payload || [])
          .sort(function (a: SocialMediaTask, b: SocialMediaTask) {
            if (a.type < b.type) {
              return -1;
            }
            if (a.type > b.type) {
              return 1;
            }
            return 0;
          });
      })
      .addCase(fetchSocialMediaTasks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch video tasks';
      })
      .addCase(startSocialMediaTasks.fulfilled, (state, action) => {
        const id: number = action.payload;
        const task = state.socialMediaTasks.find(t => t.id === id);
        if (task) {
          task.startedAt = new Date().toISOString();
        }
      })
      .addCase(claimSocialMediaTasksReward.fulfilled, (state, action) => {
        const id: number = action.payload.socialMediaTask.id;
        const task = state.socialMediaTasks.find(t => t.id === id);
        if (task) {
          task.isCompleted = true;
        }
      });
  },
});

export default socialMediaTaskSlice.reducer;

export const selectAllSocialMediaTasks = (state: { socialMediaTasks: SocialMediaTaskState }) => state.socialMediaTasks.socialMediaTasks;
export const selectSocialMediaTasksStatus = (state: { socialMediaTasks: SocialMediaTaskState }) => state.socialMediaTasks.status;
export const getVideoTasksError = (state: { socialMediaTasks: SocialMediaTaskState }) => state.socialMediaTasks.error;
