import React from 'react';
import { useHistory } from 'react-router';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';


const HBBack: React.FC = () => {

  const history = useHistory();


  return <div className='w-full color-white'>
    <IonButton onClick={(e) => {
      e.stopPropagation();
      history.goBack()
    }} fill="clear">
      <IonIcon icon={arrowBack} color='light' />
    </IonButton>
  </div>
};

export default HBBack;
