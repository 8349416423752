import { Platform, initSwipeBehavior, mockTelegramEnv, parseInitData, retrieveLaunchParams, useViewport } from "@telegram-apps/sdk-react";
import { useEffect } from "react";
import env from '../environments';


const mockTelegramData = () => {
  const initDataRaw = new URLSearchParams([
    ['user', JSON.stringify({
      id: 99281932,
      first_name: 'Michael',
      last_name: 'Johnson',
      username: 'rogue',
      language_code: 'en',
      is_premium: true,
      allows_write_to_pm: true,
    })],
    ['hash', '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31'],
    ['auth_date', '1716922846'],
    ['start_param', 'debug'],
    ['chat_type', 'sender'],
    ['chat_instance', '8428209589180549439'],
  ]).toString();

  mockTelegramEnv({
    themeParams: {
      accentTextColor: '#6ab2f2',
      bgColor: '#17212b',
      buttonColor: '#5288c1',
      buttonTextColor: '#ffffff',
      destructiveTextColor: '#ec3942',
      headerBgColor: '#17212b',
      hintColor: '#708499',
      linkColor: '#6ab3f3',
      secondaryBgColor: '#232e3c',
      sectionBgColor: '#17212b',
      sectionHeaderTextColor: '#6ab3f3',
      subtitleTextColor: '#708499',
      textColor: '#f5f5f5',
    },
    initData: parseInitData(initDataRaw),
    initDataRaw,
    version: '7.2',
    platform: 'android',
  });
}

const isMobilePlatform = (platform: Platform): boolean => {
  // Convert the platform to lowercase for case-insensitive comparison
  const lowercasePlatform = platform.toLowerCase();

  // Check if the platform is one of the specified mobile platforms
  // or if it contains 'ios' or 'android' (for potential variations)
  return (
    lowercasePlatform === 'android' ||
    lowercasePlatform === 'android_x' ||
    lowercasePlatform === 'ios' ||
    lowercasePlatform.includes('ios') ||
    lowercasePlatform.includes('android')
  );
}


export const useInitMiniApp = () => {
  try {
    const viewport = useViewport();
    if (!viewport?.isExpanded) {
      // Expand mini app
      viewport?.expand();
    }
  } catch {
    console.log('viewport undefined')
    if (env.name === 'production' || env.name === 'staging') {
      // TODO: investigate why sometimes user come here even if they use Telegram mobile app
      // For now we are just going to disable it
      // if (!window.location.pathname.startsWith('/unsupported')) {
      //   window.location.href = '/unsupported?platform=undefined';
      // }
    }
  }

  useEffect(() => {
    try {
      // If we can not retrieve launch params, we are outside tg app
      const launchParams = retrieveLaunchParams();
      console.log('Platform: ', launchParams.platform);

      if (!isMobilePlatform(launchParams.platform) && !window.location.pathname.startsWith('/unsupported')) {
        window.location.href = `/unsupported?platform=${launchParams.platform}`;
      }

      if (launchParams.platform === 'ios') {
        document.body.classList.add('ios-platform');
      }

      // Set up swipe behavior
      const [swipeBehavior] = initSwipeBehavior();
      swipeBehavior?.disableVerticalSwipe()
    } catch {
      if (env.name === 'local' || env.name === 'development') {
        mockTelegramData();
      }
    }
  }, [])
};
