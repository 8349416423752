import React, { useEffect, useState } from 'react';
import { IonContent, IonIcon, IonLabel, IonPage } from '@ionic/react';
import './Login.scss';
import { useHideIonTabBar } from '../hooks/useHideIonTabBar';
import HBBack from '../components/HBBack';
import { chevronForward } from 'ionicons/icons';
import DeleteAccountModal from '../components/DeleteAccountModal';
import CharacterCard from '../components/CharacterCard';
import { useAppDispatch, useAppSelector } from '../hooks';
import { selectApiaries } from '../redux/userSlice';
import { charactersColors } from './CharacterSelector';
import EnvironmentCard from '../components/EnvironmentCard';
import { fetchCharacter, selectCharacter, selectCharacterStatus } from '../redux/charactersSlice';
import { fetchEnvironment, selectEnvironment, selectEnvironmentStatus } from '../redux/environmentsSlice';

const SettingsPage: React.FC = () => {

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const apiaries = useAppSelector(selectApiaries);
  let apiary = null;
  if (apiaries && apiaries.length > 0) {
    apiary = apiaries[0];
  }

  useHideIonTabBar();

  const dispatch = useAppDispatch();

  const [expandChar, setExpandChar] = useState(false);
  const [expandEnv, setExpandEnv] = useState(false);

  const characterStatus = useAppSelector(selectCharacterStatus);
  const environmentStatus = useAppSelector(selectEnvironmentStatus);

  const character = useAppSelector(selectCharacter);
  const environment = useAppSelector(selectEnvironment);

  useEffect(() => {
    if (apiary && characterStatus === 'idle') {
      dispatch(fetchCharacter(apiary.character.id))
    }

    if (apiary && environmentStatus === 'idle') {
      dispatch(fetchEnvironment(apiary.environment.id))
    }
  }, [characterStatus, environmentStatus, apiary])

  return (
    <IonPage>
      <IonContent>
        <HBBack />

        <div className='text-center p-4'>
          <h2 className='text-3xl mb-8'>Settings</h2>

          {environment &&
            <div className='text-left mb-4'>
              <IonLabel class='text-base'>Environment</IonLabel>
              <EnvironmentCard disabled={false} classNames='hb-background mx-0' environment={environment} expanded={expandEnv} selected={false} onSelectedEnvironment={() => { }} onToggleExpand={() => setExpandEnv(!expandEnv)} />
            </div>
          }

          {character &&
            <div className='text-left mb-8'>
              <IonLabel class='text-base'>Character</IonLabel>
              <CharacterCard classNames='hb-background mx-0' disabled={false} character={character} color={charactersColors[character.id - 1]} expanded={expandChar} selected={false} onSelectedCharacter={() => setExpandChar(!expandChar)} onToggleExpand={() => { }} />
            </div>
          }

          <div onClick={() => setIsDeleteModalOpen(true)} className='p-4 width-full rounded-md flex justify-between items-center mb-8' style={{ background: 'rgba(0, 0, 0, 0.1)' }}>
            <IonLabel>Delete account</IonLabel>
            <IonIcon icon={chevronForward} />
          </div>

          <a href='https://hivebits.io/tap-app/privacy-policy' target='_blank'>
            <IonLabel color='light' className='underline'>Privacy policy</IonLabel>
          </a>

        </div>

        <p className='absolute bottom-1 w-full text-center text-zinc-400 text-sm'>v1.0.0-beta</p>


        <DeleteAccountModal isOpen={isDeleteModalOpen} onDidDismiss={() => setIsDeleteModalOpen(false)} />
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;
