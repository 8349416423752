// src/pages/ProductsPage.tsx
import React, { useRef } from 'react';
import { IonButton, IonModal, useIonToast, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import './DailyTaskModal.scss';
import HBIcon from '../HBIcon';
import { IconNameEnum } from '../HBIcon/HBIcon';
import { addCoinsFromOrder } from '../../redux/userSlice';
import { closeOutline } from 'ionicons/icons';
import { chunkArray, formatNumber } from '../../utils';
import Coin from '../Coin';
import { DailyTask, addTotalDailyLoginTasksEarnings, claimDailyTaskReward, selectDailyLoginTasks } from '../../redux/dailyTasksSlice';

interface DailyTaskModalProps {
  isOpen: boolean;
  onDidDismiss: () => void;
  today: string,
  dailyLoginTasks: DailyTask[];
  hasActiveTask: boolean;
}

const DailyTaskModal: React.FC<DailyTaskModalProps> = ({ isOpen, onDidDismiss, today, dailyLoginTasks, hasActiveTask }) => {

  const modal = useRef<HTMLIonModalElement>(null);

  const dispatch = useAppDispatch();

  const [present] = useIonToast();
  const rewardClaimedToast = () => {
    present({
      message: 'Reward successfully claimed!',
      duration: 1000,
      position: 'top',
      color: 'success'
    });
  }

  const handleClaimReward = async (activeTask: DailyTask | undefined) => {
    dispatch(claimDailyTaskReward({ timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }));

    if (activeTask) {
      dispatch(addCoinsFromOrder({ amount: activeTask.reward }));
      dispatch(addTotalDailyLoginTasksEarnings({ amount: activeTask.reward }))
    }

    rewardClaimedToast()
    modal.current?.dismiss();
  }

  const dailyLoginTaskChunks = chunkArray(dailyLoginTasks || [], 4);
  const activeTask = dailyLoginTasks.find((task: DailyTask) => !task.isCompleted);
  const disableButton = !hasActiveTask;

  return (
    <IonModal ref={modal} isOpen={isOpen} onDidDismiss={onDidDismiss} initialBreakpoint={1} breakpoints={[0, 1]} className='hb-modal'>
      <div className='hb-modal-content daily-task-modal'>

        <div className='flex justify-end text-3xl'>
          <IonIcon src={closeOutline} onClick={() => modal.current?.dismiss()}></IonIcon>
        </div>

        <div className='flex flex-col items-center text-white mb-6'>
          <div className='mb-8'>
            <HBIcon name={IconNameEnum.CalendarDay} className='icon' size={72} />
          </div>

          <h2 className='text-3xl mb-4 text-center'>Daily reward</h2>

          <p className='text-center text-sm mb-4'>Accrue coins for logging into the game daily without skipping</p>

          <IonGrid className='w-full'>
            {dailyLoginTaskChunks.map((chunk, rowIndex) => (
              <IonRow key={rowIndex}>
                {chunk.map((task, colIndex) => (
                  <IonCol key={colIndex}>
                    <div
                      className={`rounded-md item flex flex-col items-center
                        ${task.isCompleted ? 'done' : ''}
                        ${task.day === activeTask?.day && today === task.claimingDate ? 'active' : ''}`}
                    >
                      Day {task.day}
                      <div className='icon'>
                        <Coin marginRight={0} className='my-1' />
                      </div>
                      +{formatNumber(task.reward)}
                    </div>
                  </IonCol>
                ))}
                {/* Add empty columns if necessary to fill the row */}
                {chunk.length < 4 && Array.from({ length: 4 - chunk.length }).map((_, index) => (
                  <IonCol key={`empty-${index}`}></IonCol>
                ))}
              </IonRow>
            ))}
          </IonGrid>
        </div>
        <IonButton disabled={disableButton} color={disableButton ? 'medium' : 'primary'} className='mb-6' expand='block' onClick={() => handleClaimReward(activeTask)}>
          {disableButton ? 'Come back tomorrow' : 'Claim'}
        </IonButton>
      </div>
    </IonModal>
  );
};

export default DailyTaskModal;
